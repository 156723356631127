export function alertModal(text, path)  {
  $(function(){
    if ($('#js-modal').length == 0) {
      $('body').prepend(
        `
          <div class="p-modal__wrapper" id="js-modal">
            <div class="p-modal__panel -red">
              <div class="p-modal__top-wrpper">
                ${convertNewLine(text)}
              </div>
              <div class="p-modal__bottom-wrpper">
                <a rel="nofollow" data-method="delete" href="${path}">
                  <button class="c-button -whitesmoke">ログアウト</button>
                </a>
              </div>
            </div>
            <div class="p-modal__black-background -alert"></div>
          </div>
        `
      )
    }
  })
}

function convertNewLine(text) {
  return text.replace(/\n/g, "<br>")
}
